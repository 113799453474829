<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>Document Dashboard</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                v-for="(count, type) in groupedData"
                :key="type"
                cols="12"
                sm="4"
                md="6"
              >
                <v-card class="mb-4" outlined>
                  <v-card-title>{{ type }}</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col
                        v-for="(statusCount, status) in count.statuses"
                        :key="status"
                        cols="6"
                        sm="6"
                        md="6"
                      >
                        <v-btn
                          :color="statusColor(status)"
                          block
                          @click="filterDocuments(type, status)"
                        >
                          {{ status }}: {{ statusCount }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <GChart type="PieChart" :data="salesByCategory" :options="pieChartOptions" />
      </v-col>
      <v-col cols="12" sm="6">
        <GChart type="PieChart" :data="salesByBrand" :options="pieChartOptions" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GChart
          type="ColumnChart"
          :data="topSellingProducts"
          :options="columnChartOptions"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GChart type="LineChart" :data="salesTrends" :options="lineChartOptions" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <GChart type="LineChart" :data="averageSales" :options="lineChartOptions" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { webserver, getdays_ago, getToday } from "../../services/webserver.js";
import { GChart } from "vue-google-charts";

export default {
  components: { GChart },
  mounted() {
    this.$store.dispatch("setNavStatus", true);
    this.$store.dispatch("set_menu", true);
    this.get_documents();
    this.get_items();
  },
  data() {
    return {
      headers: [
        { text: "Document Type", value: "doc_type" },
        { text: "Statuses", value: "statuses" },
      ],
      documents: [
        { doc_type: "invoice", status: "pending", issue_date: "2023-01-01" },
        { doc_type: "invoice", status: "completed", issue_date: "2023-01-02" },
        { doc_type: "report", status: "in_progress", issue_date: "2023-01-03" },
        { doc_type: "report", status: "pending", issue_date: "2023-01-04" },
        { doc_type: "receipt", status: "completed", issue_date: "2023-01-05" },
      ],
      fromDate: getdays_ago(-30),
      toDate: getToday(),
      salesData: [
        {
          issue_date: getToday(),
          qty: 10,
          code: "P001",
          product_name: "Producto A",
          category: "Categoria 1",
          group: "Grupo 1",
          brand: "Marca X",
          reference: "Ref 1",
          size: "M",
          tItem: 100,
        },
      ],
      pieChartOptions: {
        title: "Sales Distribution",
        is3D: true,
      },
      columnChartOptions: {
        title: "Top Selling Products",
        hAxis: { title: "Products" },
        vAxis: { title: "Sales" },
      },
      lineChartOptions: {
        title: "Sales Over Time",
        hAxis: { title: "Date" },
        vAxis: { title: "Sales" },
        curveType: "function",
      },
    };
  },
  methods: {
    async get_documents() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "docs",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.company.account },
          { field: "Date(issue_date)", operator: ">=", value: this.fromDate },
          { field: "Date(issue_date)", operator: "<=", value: this.toDate },
          { field: "doc_type", operator: " IN ", value: "'POS','FE'" },
        ],
      };
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_table",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      var result = await promise;
      console.log(result);
      if (result) {
        result.forEach((itm) => {
          itm.total = parseFloat(itm.total);
        });
        this.documents = result;
      }
    },
    async get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: "doc_items",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.company.account },
          { field: "Date(issue_date)", operator: ">=", value: this.fromDate },
          { field: "Date(issue_date)", operator: "<=", value: this.toDate },
        ],
      };
      let promise = new Promise((resolve, reject) => {
        webserver(
          "get_table",
          qry,
          function (data) {
            resolve(data);
          },
          function () {
            reject([]);
          }
        );
      });
      var result = await promise;
      if (result) {
        result.forEach((itm) => {
          itm.qty = parseFloat(itm.qty);
          itm.tItem = parseFloat(itm.tItem);
        });
        console.log(result);
        this.salesData = result;
      }
    },
    statusColor(status) {
      switch (status) {
        case "PENDIENTE":
          return "orange";
        case "DESPACHADO":
          return "green";
        case "in_progress":
          return "blue";
        case "ANULADO":
          return "grey";
        default:
          return "white";
      }
    },
    filterDocuments(type, status) {
      // Implement your filter logic here
      alert(`Filtering ${type} documents with status: ${status}`);
    },
  },
  computed: {
    groupedData() {
      return this.documents.reduce((acc, doc) => {
        if (!acc[doc.doc_type]) {
          acc[doc.doc_type] = { statuses: {} };
        }
        if (!acc[doc.doc_type].statuses[doc.status]) {
          acc[doc.doc_type].statuses[doc.status] = 0;
        }
        acc[doc.doc_type].statuses[doc.status] += 1;
        return acc;
      }, {});
    },
    salesByCategory() {
      const categoryData = [["Category", "Sales"]];
      const categoryMap = this.salesData.reduce((acc, sale) => {
        acc[sale.category] = (acc[sale.category] || 0) + sale.tItem;
        return acc;
      }, {});
      for (const [key, value] of Object.entries(categoryMap)) {
        categoryData.push([key, value]);
      }
      return categoryData;
    },
    salesByBrand() {
      const brandData = [["Brand", "Sales"]];
      const brandMap = this.salesData.reduce((acc, sale) => {
        acc[sale.brand] = (acc[sale.brand] || 0) + sale.tItem;
        return acc;
      }, {});
      for (const [key, value] of Object.entries(brandMap)) {
        brandData.push([key, value]);
      }
      return brandData;
    },
    topSellingProducts() {
      const productData = [["Product", "Sales"]];
      const productMap = this.salesData.reduce((acc, sale) => {
        acc[sale.product_name] = (acc[sale.product_name] || 0) + sale.qty;
        return acc;
      }, {});
      const sortedProducts = Object.entries(productMap)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 10); // Mostrar los 10 productos más vendidos
      for (const [key, value] of sortedProducts) {
        productData.push([key, value]);
      }
      return productData;
    },
    salesTrends() {
      const trendData = [["Date", "Sales"]];
      const trendMap = this.salesData.reduce((acc, sale) => {
        const date = new Date(sale.issue_date).toISOString().split("T")[0];
        acc[date] = (acc[date] || 0) + sale.tItem;
        return acc;
      }, {});
      for (const [key, value] of Object.entries(trendMap).sort()) {
        trendData.push([key, value]);
      }
      return trendData;
    },
    averageSales() {
      const avgData = [["Date", "Average Sales"]];
      const trendMap = this.salesData.reduce((acc, sale) => {
        const date = new Date(sale.issue_date).toISOString().split("T")[0];
        acc[date] = (acc[date] || 0) + sale.tItem;
        return acc;
      }, {});
      const dates = Object.keys(trendMap).sort();
      const cumulativeSales = [];
      let totalSales = 0;
      for (const date of dates) {
        totalSales += trendMap[date];
        cumulativeSales.push(totalSales);
        avgData.push([date, totalSales / cumulativeSales.length]);
      }
      return avgData;
    },
  },
  watch: {},
};
</script>

<style lang="scss"></style>
